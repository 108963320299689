import path from "path";

export const i18n = {
  locales: ["en", "ko"],
  defaultLocale: "ko",
};

export default {
  i18n,
  localePath: path.resolve("./public/locales"),
  reloadOnPrerender: process.env.NODE_ENV === "development", // 개발 환경에서 핫 리로드 활성화
};
